<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner v-if="loading" line height=""/>
            <v-row align="center" v-for="(exam, i) in exams" :key="i">
                <v-col cols="12" md="4">
                    <div class="title">
                        {{ exam.title }}
                    </div>
                    <div class="subtitle">
                        {{ exam.subtitle }}
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <upload-exam
                        @change="exam.file = $event; exam.filename = $event.name"
                        :filename="exam.filename"
                        :ref="`exam${i}`"
                    />
                </v-col>
                <v-col cols="6" md="2" class="text-center">
                    <v-btn
                        color="primary" class="normal-btn"
                        depressed :loading="exam.loading"
                        @click="uploadImg(exam)"
                        :disabled="!exam.file"
                    >
                        <v-icon left v-text="'$image'"/>
                        {{ exam.filename && !exam.file ? 'Subido' : 'Subir' }}
                    </v-btn>
                </v-col>
                <v-col cols="6" md="2" class="text-center">
                    <v-btn
                        color="ternary" class="normal-btn"
                        depressed
                        @click="previewImg(exam)"
                        :disabled="!exam.filepath"
                    >
                        <v-icon left v-text="'$eye'"/>
                        Visualizar
                    </v-btn>
                </v-col>
                <v-col cols="12" class="py-0" v-if="isMobile">
                    <v-divider/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" class="normal-btn" small depressed @click="goBack">
                Volver
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import UploadExam from "@/components/common/inputs/UploadExam";
import MasterPanel from "@/components/layouts/MasterPanel";
import httpService from "@/services/httpService";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import {DOCUMENTS} from "@/utils/Constants";

export default {
    name: "ClientUploadExams",
    components: {LoadingSpinner, MasterPanel, UploadExam},
    data() {
        return {
            loading: false,
            exams: [
                {
                    title: 'Informe endoscopia',
                    subtitle: 'Seleccione el archivo con el informe de endoscopia, haciendo click en ‘Examinar’. Luego suba la imagen seleccionada haciendo click en ‘Subir’.',
                    file: null,
                    filename: '',
                    filepath: '',
                    loading: false,
                    document_type_id: DOCUMENTS.ENDOSCOPY,
                },
                {
                    title: 'Resultados de laboratorio',
                    subtitle: 'Seleccione el archivo de los resultados de exámenes de laboratorio, haciendo click en ‘Examinar’. Luego suba la imagen seleccionada haciendo click en ‘Subir’.',
                    file: null,
                    filename: '',
                    filepath: '',
                    loading: false,
                    document_type_id: DOCUMENTS.LABORATORY,
                },
                {
                    title: 'Informe cardiológico',
                    subtitle: 'Seleccione el archivo con el informe cardiológico, haciendo click en ‘Examinar’. Luego suba la imagen seleccionada haciendo click en ‘Subir’.',
                    file: null,
                    filename: '',
                    filepath: '',
                    loading: false,
                    document_type_id: DOCUMENTS.CARDIOLOGICAL,
                },
                {
                    title: 'Resultado de la ecografía',
                    subtitle: 'Seleccione el archivo de los resultados de la ecografía, haciendo click en ‘Examinar’. Luego suba la imagen seleccionada haciendo click en ‘Subir’.',
                    file: null,
                    filename: '',
                    filepath: '',
                    loading: false,
                    document_type_id: DOCUMENTS.SONOGRAPHY,
                },
                {
                    title: 'Carga tu pase nutricional',
                    subtitle: 'Seleccione el archivo de tu pase nutricional, haciendo click en ‘Examinar’. Luego suba la imagen seleccionada haciendo click en ‘Subir’.',
                    file: null,
                    filename: '',
                    filepath: '',
                    loading: false,
                    document_type_id: DOCUMENTS.NUTRITIONAL_PASS,
                },
                {
                    title: 'Carga tu pase psicológico',
                    subtitle: 'Seleccione el archivo de tu pase psicológico, haciendo click en ‘Examinar’. Luego suba la imagen seleccionada haciendo click en ‘Subir’.',
                    file: null,
                    filename: '',
                    filepath: '',
                    loading: false,
                    document_type_id: DOCUMENTS.PSYCHOLOGICAL_PASS,
                },
            ]
        }
    },
    computed: {
        userId(vm = this) {
            return vm.$route.params.id
        },
        path(vm = this) {
            return `users/${vm.userId}/exams`
        }
    },
    async mounted() {
        const vm = this
        await vm.setDefaults()
    },
    methods: {
        async setDefaults() {
            const vm = this
            vm.loading = true
            let response = await httpService.get(`client/${vm.userId}/exams`)
            // console.log('response', response)

            response.data.exams.forEach(exam => {
                let index = vm.exams.findIndex(e => e.document_type_id === exam.document_type_id)
                if (index >= 0) {
                    vm.exams[index].filename = exam.name
                    vm.exams[index].filepath = exam.path
                }
            })

            vm.loading = false
        },
        async uploadImg(exam) {
            const vm = this
            exam.loading = true
            let response = await httpService.postFile(`client/${vm.userId}/exam/upload`, {
                file: exam.file,
                document_type_id: exam.document_type_id,
                path: vm.path
            })
            vm.toast(response.data.message, response.data.error ? 'error' : 'success')
            await vm.setDefaults()

            exam.loading = false
        },
        previewImg(exam) {
            const vm = this
            window.open(vm.getFilePath(exam.filepath, exam.filename), '_blank')
        },
    }
}
</script>

<style scoped>

.title {
    color: #1a1a1a;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
}

.subtitle {
    color: #1a1a1a;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
}

</style>